import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';

import { Box, Grid, List, ListItem, Stack, TextField, ThemeProvider } from '@mui/material';
import { PrismicRichText } from '@prismicio/react';
import { accenttheme, darktheme, lighttheme } from '../themes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

const PriceSlice = ({ sliceData, index, direction }) => {

    let theme = lighttheme;

    console.log(sliceData);

    return (
        <ThemeProvider theme={theme}>
            <Grid container
                id={"price"}
                direction={"row"}
                justifyContent="center"
                alignItems="flex-start"
                sx={{ backgroundColor: "background.secondary" }}
                py={2}>
               
                <Grid item xs={0} md={2} px={3} ></Grid>
                <Grid item xs={12} md={8} px={3}  display="flex" py={4} sx={{ justifyContent: "flex-start" }}>
                    <Box>
                        <Typography sx={{ typography: { sm: 'h1', xs: 'h2' } }} color="text.primary">Priser</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}  md={2} px={3}></Grid>

                <Grid item xs={12}  md={4}  px={3}  display="flex" sx={{ justifyContent: { md: 'center', sm: 'center' } }}>
                    <Box>
                        <PrismicRichText
                            field={sliceData.primary.monthly_title}
                            components={{
                                heading2: ({ children }) => <Typography sx={{ typography: { sm: 'label', xs: 'label_small', background: theme.palette.primary.main, color: theme.palette.primary.contrastText } }} py={0} mb={4} px={3}>{children}</Typography>,

                            }}
                        />
                        <PrismicRichText
                            field={sliceData.primary.features_monthly_deal}
                            components={{
                                listItem: ({ children }) => <Stack direction="row"><CheckCircleIcon /><Typography sx={{ typography: { sm: 'list', xs: 'list' } }} py={0} px={3}>{children}</Typography></Stack>,

                            }}
                        />
                        <Typography sx={{ typography: { sm: 'price1', xs: 'price2' } }} ml={4} pt={3} px={3}>{sliceData.primary.price_monthly_deal}:- /mån</Typography>

                        <PrismicRichText
                            field={sliceData.primary.extra_text_1}
                            components={{
                                paragraph: ({ children }) => <Typography sx={{ typography: { sm: 'p', xs: 'p', fontStyle: "italic" } }} ml={4} px={3}>{children}</Typography>,

                            }}
                        />

                        {/* ---------------------------------- */}
                        <PrismicRichText
                            field={sliceData.primary.addon_title}
                            components={{
                                heading2: ({ children }) => <Typography sx={{ typography: { sm: 'label', xs: 'label_small', background: theme.palette.primary.main, color: theme.palette.primary.contrastText } }} mt={6} mb={4} py={0} px={3}>{children}</Typography>,

                            }}
                        />
                        {sliceData.items.map((item, index) => (
                            <React.Fragment key={index}>
                                <PrismicRichText
                                   
                                    field={item.addon_name}
                                    components={{
                                        paragraph: ({ children }) => (
                                            <Grid container
                                                direction={"row"}>
                                                <Grid item xs={1}>
                                                    <AddCircleIcon />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography sx={{ typography: { sm: 'list', xs: 'list' } }} py={0} px={3}>{children}</Typography>
                                                    <Typography sx={{ typography: { sm: 'price3', xs: 'price4', textAlign: "left" } }} py={0} px={3}>{item.addon_price}:-/mån</Typography><div style={{ margin: "30px" }} />
                                                </Grid>
                                            </Grid>


                                        )
                                    }}
                                />
                            </React.Fragment>
                        ))}
                    </Box>
                </Grid>

             
                {/* ---------------------------------- */}
                
                <Grid item xs={12} md={4} px={3} display="flex" sx={{ justifyContent: { md: 'center', sm: 'center' } }}>
                    <Box >
                        <PrismicRichText
                            field={sliceData.primary.starter_pack_title}
                            components={{
                                heading2: ({ children }) => <Typography sx={{ typography: { sm: 'label', xs: 'label_small', background: theme.palette.primary.main, color: theme.palette.primary.contrastText } }} py={0} mb={4} px={3}>{children}</Typography>,

                            }}
                        />

                        <PrismicRichText
                            field={sliceData.primary.starter_pack_1_name}
                            components={{
                                paragraph: ({ children }) => <Stack direction="row"><PlayCircleFilledWhiteIcon /><Typography sx={{ typography: { sm: 'list', xs: 'list' } }} py={0} px={3}>{children}</Typography></Stack>,

                            }}
                        />
                        <Typography component="span" sx={{ typography: { sm: 'price3', xs: 'price4', textAlign: "left" } }} py={0} ml={6.5}>{sliceData.primary.starter_pack_1_price_current}:-</Typography>
                        <Typography component="span" sx={{ typography: {fontStyle: "italic" } }}> (ord. pris: {sliceData.primary.starter_pack_1_price_original}:-)</Typography>
                        <PrismicRichText
                            field={sliceData.primary.starter_pack_2_name}
                            components={{
                                paragraph: ({ children }) => <Stack direction="row"><PlayCircleFilledWhiteIcon /><Typography sx={{ typography: { sm: 'list', xs: 'list' } }} py={0} px={3}>{children}</Typography></Stack>,

                            }}
                        />
                        <Typography component="span" sx={{ typography: { sm: 'price3', xs: 'price4', textAlign: "left" } }} py={0} ml={6.5}>{sliceData.primary.starter_pack_2_price_current}:-</Typography>
                        <Typography component="span" sx={{ typography: {fontStyle: "italic" } }}> (ord. pris: {sliceData.primary.starter_pack_2_price_original}:-)</Typography>
                        <div style={{paddingBottom: "40px"}} />
                        <PrismicRichText
                            field={sliceData.primary.starter_pack_descriptions}
                            components={{
                                paragraph: ({ children }) => <Typography sx={{ typography: { sm: 'p', xs: 'p' } }} ml={0} mb={3} px={3}>{children}</Typography>,

                            }}
                        />

                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider >
    )

}
export default PriceSlice
