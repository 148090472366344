import * as React from 'react';
import { Box, Stack, ThemeProvider } from '@mui/material';
import topbanner1 from '../assets/topbanner1_1.webp';
import topbannerimg1 from '../assets/topbannerimg.png';
import { accenttheme } from '../themes';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const TopSlice = ({ color, direction }) => {

    return (
        <ThemeProvider theme={accenttheme}>
            <Stack
                direction={direction}
                justifyContent="center"
                alignItems="center"
                alignContent='center'
                spacing={0}
                sx={{ minHeight: { md: "400px", xs: "200px"}, backgroundColor: "background.default", overflow: "hidden" }}
            >
                <Box
                    component="img"
                    sx={{
                    maxWidth: { xs: "230px", sm: "330px", md: "500px"},
                    paddingLeft:"1rem", alignContent:"right",
                    }}
                    src={topbanner1}
                />
            

                <Box
                    component="img"
                    sx={{
                    maxHeight: { xs: "100px", sm: "130px", md: "300px" },
                    paddingRight:"1rem", alignContent:"left"
                    }}
                    style={{ 
                        filter: "drop-shadow(2px 4px 10px #00000033",
                    }}
                    src={topbannerimg1}
                />

            </Stack>
        </ThemeProvider>
    );
};
export default TopSlice;
