import './App.css';

import { ThemeProvider } from '@emotion/react';
import { Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { useEffect } from 'react';
import Footer from './components/Footer';
import TextImageSlice from './components/TextImageSlice';
import TopNavigation from './components/TopNavigation';
import TopSlice from './components/TopSlice';
import { lighttheme } from "./themes"
import ContactSlice from './components/ContactSlice';
import PriceSlice from './components/PriceSlice';

function App() {

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }
  
  //const [firstDocument] = useFirstPrismicDocument()
  const [startPage] = usePrismicDocumentByUID('homepage', 'the-start-page')

  useEffect(() => {
    if (startPage) {
      //console.log(startPage)
      //console.log(startPage)

    }
  }, [startPage])

  const generateContent = () => {
    if (startPage) {
      let slices = []
      
      let currentDirection = "row"

      startPage.data.body.map((sliceData, index) => {
        switch (sliceData.slice_type) {
          case "test-image-header-text":
            slices.push( <TextImageSlice key={index.toString()} sliceData={ sliceData } index={index} direction={currentDirection} />)
            break;
          case "pricing_iteration_1":
            slices.push( <PriceSlice key={index.toString()} sliceData={ sliceData } index={index} direction={currentDirection} />)
            break;
          case "contact_form":
            slices.push( <ContactSlice key={index.toString()} sliceData={ sliceData } index={index} direction={currentDirection} />)
            break;
        }

       currentDirection = index % 2 !== 1 ? "row" : "row-reverse";
         
      })
      return slices
    }
    return null
  }

  

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://mui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

 


  return (
    <ThemeProvider theme={lighttheme}>
      <div className="App">
          <Container disableGutters maxWidth={false}>
            <TopNavigation />
            <TopSlice color="accent.main" direction="row" />
            { generateContent() }
            <Footer />
          </Container>
        </div>
              
    </ThemeProvider>
  );
}

export default App;
