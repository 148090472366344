import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import brandcamLogo from '../assets/brandcam-logo-pos.svg';
import { Box, Grid, Stack, ThemeProvider } from '@mui/material';
import { accenttheme, darktheme, lighttheme } from '../themes';
import { PrismicRichText } from '@prismicio/react'
import * as prismicH from '@prismicio/helpers'

function htmlSerializer(type, element, text, children) {
    if (type === 'label')
        return `<strong className="${element.data.label}">${children}</strong>`
    return null
}



const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const MODE_TEXT = "text";
const MODE_MEDIA = "media";
const MODE_TEXT_MEDIA = "text-media";

const TextImageSlice = ({ sliceData, index, direction }) => {
    let mode = MODE_TEXT_MEDIA

    let theme = lighttheme;

    const headline = sliceData.primary.slice_title[0].text;

    console.log("button ------------------------------------>");
    console.log(sliceData.primary.button_link)

    const buttonLink = sliceData.primary.button_link.target === "_blank" ? { url: sliceData.primary.button_link.url, type: "external" } : sliceData.primary.button_link.url ? { url: "#" + sliceData.primary.button_link.url.split("#")[1], type: "internal" } : null;

    console.log("===============================)> buttonLink ")
    console.log(buttonLink)

    console.log("sliceData");
    console.log(sliceData);

    switch (sliceData.primary.color_theme) {
        case "Bright":
            theme = lighttheme;
            break
        case "Dark":
            theme = darktheme;
            break
        case "Colorful Yellow":
            theme = accenttheme;
            break
        default:
            theme = lighttheme;
    }

    if (sliceData.items[0].slider_image.url === undefined && (sliceData.primary.slice_title[0].text !== "" || sliceData.primary.sub_title[0].text !== "")) {
        console.log("no media")
        mode = MODE_TEXT;
        console.log("mode is inside " + mode);
    }
    else if (sliceData.primary.slice_title[0].text === "" && sliceData.primary.sub_title[0].text === "") {
        console.log("no text")
        mode = MODE_TEXT;
        console.log("mode is inside " + mode);
        mode = MODE_MEDIA;
    }
    else {
        console.log("mode is inside " + mode);
    }



    const Media = () => {
        if (sliceData.items[0].slider_image.url !== undefined) {
            if (sliceData.items.length === 1) {
                return (
                    <Box
                        component="img"
                        sx={{
                            maxWidth: { xs: "95%", md: "400px" },
                            alignContent: 'right'
                        }}
                        src={sliceData.items[0].slider_image.url}
                    />
                )
            }
            else {
                return (
                    <>GENERERA BILDSPEL</>
                )
            }
        }
        else {

            return null
        }
    }



    if (sliceData.primary.slice_visible) {
        if (mode === MODE_TEXT_MEDIA) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid container index={index}
                        direction={direction}
                        justifyContent="center"
                        alignItems="center"

                        spacing={3}
                        sx={{ minHeight: "400px", backgroundColor: "background.default", pb: { md: "40px", xs: "50px" }, px: { md: "40px", xs: "30px" } }}>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" sx={{ justifyContent: { md: direction === "row" ? "flex-end" : "flex-start", xs: "center" } }}>
                                <Media />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" px={0} sx={{ justifyContent: { md: direction === "row" ? "flex-start" : "flex-end", xs: "center" } }}>
                            <Box sx={{ maxWidth: { xs: "100%", md: "400px" }}}>
                                <PrismicRichText
                                    field={sliceData.primary.slice_title}
                                    components={{
                                        heading1: ({ children }) => <Typography my={0} sx={{ typography: { sm: 'h1', xs: 'h2' } }} color="text.primary">{children}</Typography>,
                                        strong: ({ children }) => <Typography my={0} sx={{ typography: { sm: 'h1', xs: 'h2', fontWeight: "800" } }} color="text.highlight" >{children}</Typography>

                                    }}
                                />
                               
                                <PrismicRichText
                                    field={sliceData.primary.sub_title}
                                    components={{
                                        heading2: ({ children }) => <Typography my={3} sx={{ typography: { sm: 'h3', xs: 'h4' } }} color="text.primary">{children}</Typography>,

                                    }}
                                />
                                {buttonLink != null &&
                                    <>
                                        <Button target={buttonLink.type === "external" ? "_blank" : "_self"} variant="contained" color="primary" fullWidth style={{ height: "60px" }} href={buttonLink?.url}>{sliceData.primary.button_label}</Button>
                                        <PrismicRichText
                                            field={sliceData.primary.button_sub_title}
                                            components={{
                                                paragraph: ({ children }) => <Typography sx={{ typography: { sm: 'desc', xs: 'desc' } }} color="text.primary">{children}</Typography>,

                                            }}
                                        /></>

                                }

                            </Box>
                        </Grid>
                    </Grid>
                    <Stack
                        direction={direction}
                        justifyContent="space-evenly"
                        alignItems="center"
                        alignContent='center'
                        spacing={2}

                    >
                        <Box>

                        </Box>
                        <Box >

                        </Box>

                    </Stack>
                </ThemeProvider>
            )
        }
        else if (mode === MODE_TEXT) {
            console.log("mode is text!!!!!!!!!!!!!! " + mode);
            return (
                <ThemeProvider theme={theme}>
                    <Grid container index={index}
                        direction={direction}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: "200px", backgroundColor: "background.default", pt: { md: "30px", xs: "40px" }, pb: { md: "55px", xs: "60px" }, px: { md: "40px", xs: "30px" } }}>
                        <Grid item xs={12} display="flex" justifyContent="center" sx={{ maxWidth: { xs: "95%", md: "800px" } }}>
                            <Box  >
                                <PrismicRichText
                                    field={sliceData.primary.slice_title}
                                    components={{
                                        heading1: ({ children }) => <Typography color="text.primary" sx={{ typography: { sm: 'subtitle1', xs: 'subtitle2' } }}>{children}</Typography>,
                                        strong: ({ children }) => <Typography variant="hsubtitle11" color="text.highlight" sx={{ typography: { sm: 'subtitle1', xs: 'subtitle2' } }}>{children}</Typography>,

                                    }}
                                />


                                {buttonLink != null && <Button target={buttonLink.type === "external" ? "_blank" : "_self"} variant="contained" color="primary" fullWidth style={{ height: "60px" }} href={buttonLink?.url}>{sliceData.primary.button_label}</Button>}

                            </Box>
                        </Grid>
                    </Grid>
                    <Stack
                        direction={direction}
                        justifyContent="space-evenly"
                        alignItems="center"
                        alignContent='center'
                        spacing={2}

                    >
                        <Box>

                        </Box>
                        <Box >

                        </Box>

                    </Stack>
                </ThemeProvider>
            )
        }
        else if (mode === MODE_MEDIA) {
            return null
        }
    } else {
        return null;
    }
};
export default TextImageSlice
