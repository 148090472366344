import { createTheme } from '@mui/material/styles';

const basetheme = createTheme({})

const BLACK = '#1C1C1C'
const WHITE = '#F0EFEE'
const DARK_WHITE = '#DADADA'
const ACCENT = '#ffd54f'
const TYPOGRAPHY = {
    h1: {
        fontSize: '3rem',
        fontWeight: 800,
    },

    h2: {
        fontSize: '2rem',
        fontWeight: 800,
    },
    h2alt: {
        fontSize: '1.6rem',
        fontWeight: 400,
    },
    h3: {
        fontSize: '1.3rem',
        fontWeight: 300,
    },
    h4: {
        fontSize: '1rem',
        fontWeight: 300,
    },
    subtitle1: {
        fontSize: '1.5rem',
        fontWeight: 400,
    },
    subtitle2: {
        fontSize: '1.2rem',
        fontWeight: 400,
    },
    label: {
        fontSize: '1.6rem',
        fontWeight: 800,
    },
    label_small: {
        fontSize: '1.3rem',
        fontWeight: 800,
    },
    list: {
        fontSize: '1.3rem',
        fontWeight: 500,
        listStyleType: "circle"
    },
    price1: {
        fontSize: '2.5rem',
        fontWeight: 600,
    },
    price2: {
        fontSize: '2rem',
        fontWeight: 600,
    },
    price3: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    price4: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    desc: {
        fontSize: '1rem',
        fontWeight: 400,
        fontStyle: "italic",
        marginLeft: ".5rem",
        color: "#666"
    },
    p: {
        fontSize: '1rem',
        fontWeight: 400,
    },
    tiny: {
        fontSize: '0.9rem',
        fontWeight: 400,
        color: "#666666"
    },
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,

}


const darktheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: ACCENT,
            contrastText: BLACK,
        },
        secondary: {
            main: WHITE,
            contrastText: BLACK
        },
        background: {
            default: BLACK,
            paper: "#7d8790",
        },
        text: {
            primary: WHITE,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: ACCENT
        },
        action: {
            active: '#ff0000',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#ff0000',
        }
    },
    typography: TYPOGRAPHY
});

const lighttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: ACCENT,
            contrastText: BLACK
        },
        background: {
            default: WHITE,
            secondary: DARK_WHITE,
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246"
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        }
    },
    typography: TYPOGRAPHY
});

const accenttheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: BLACK,
            contrastText: WHITE,
        },
        secondary: {
            main: WHITE,
            contrastText: BLACK
        },
        background: {
            default: ACCENT,
            paper: BLACK
        },
        text: {
            primary: BLACK,
            secondary: '#7d8790',
            disabled: '#7d8790',
            icon: '#ffffff',
            dark: '#11151a',
            highlight: "#fae246",
            paper: WHITE
        },
        action: {
            active: '#7d8790',
            hover: '#58a3e2',
            selected: '#ff00ff',
            focus: '#ff00ff',
            disabled: '#464c54',
        },
        paper: {
            background: "red"
          }

    },
    typography: TYPOGRAPHY
});

export { darktheme, lighttheme, accenttheme };