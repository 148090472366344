import AppBar from '@mui/material/AppBar';
import * as React from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { Box, Divider, Drawer, IconButton, Stack, ThemeProvider } from '@mui/material';
import brandcamLogo from '../assets/brandcam-logo-pos.svg';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { accenttheme } from '../themes';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];



const TopNavigation = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <ThemeProvider theme={accenttheme}>
            <AppBar
                position="sticky"
                elevation={0}
                sx={{ backgroundColor: "background.default" }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent='center'
                    spacing={2}
                >
                    <Box >
                        <img src={brandcamLogo} alt="Brandcam" style={{ width: "200px", margin: "10px" }} />
                    </Box>
                    <Box sx={{ my: 1, mx: 1.5, display: { md: 'block', sm: 'none', xs: 'none' } }}>
                        <nav>
                            <Link
                                variant="button"
                                href="#"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Start
                            </Link>
                            <Link
                                variant="button"
                                href="#price"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Priser
                            </Link>
                            <Link
                                variant="button"
                                href="#contact"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Kontakt
                            </Link>
                        </nav>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" sx={{ width: "200px" }} >
                        <Button href="https://app.brandcam.se" size="large" variant="contained" sx={{ my: 1, mx: 1.5, display: { md: 'flex', sm: 'none', xs: 'none' } }}>
                            Logga in
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="right" p={2} sx={{ width: "200px", display: { md: 'none', sm: 'flex', xs: 'flex' } }} >
                        <IconButton aria-label="menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <MenuIcon color="primary" sx={{ fontSize: 35 }} />
                        </IconButton>
                    </Box>
                </Stack>


            </AppBar>
            <Drawer open={isMenuOpen} PaperProps={{
                sx: {
                    backgroundColor: "background.paper",
                    color: "text.paper",
                    minWidth: "40%"
                }
            }} anchor={"right"} onClose={() => setIsMenuOpen(false)}>
                <Box display="flex" justifyContent="right" p={2}  >

                    <IconButton aria-label="menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <CloseIcon color="text.paper" sx={{ fontSize: 35 }} />
                    </IconButton>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: "20px",
                    flexWrap: "wrap",
                    margin: "auto",
                    my: 1, mx: 1.5
                }} >

                    <Link
                        variant="h2"
                        color="text.paper"
                        href="#"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Start
                    </Link>
                    <Link
                        variant="h2"
                        color="text.paper"
                        href="#price"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Priser
                    </Link>
                    <Link
                        variant="h2"
                        color="text.paper"
                        href="#contact"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Kontakt
                    </Link>
                    <Divider color="text.paper" width="30px" sx={{ bgcolor: "secondary.light", margin: "14px" }} />
                    <Link
                        variant="h2alt"
                        color="text.paper"
                        href="https://app.brandcam.se"
                        sx={{ my: 1, mx: 1.5, textDecoration: 'none' }}
                        onClick={() => setIsMenuOpen(false)}
                    >
                        Logga in
                    </Link>

                </Box>

            </Drawer>
        </ThemeProvider>
    );
};
export default TopNavigation;
